<template>
  <div>

  </div>
</template>

<script lang="ts">

import { ComponentMixin } from '~/mixins/component.mixin';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'CustomRedirectToForProperty',
  mixins: [ComponentMixin],

  setup() {
    const url = useRequestURL();

    function getRightValue(str) {
      // Split the string using the underscore as the delimiter
      const parts = str.split('_');
      // Return the second part (value after the underscore)
      return parts[1];
    }

    const propertyId = url.searchParams.get('id') ? getRightValue(url.searchParams.get('id')) : undefined;

    if (propertyId) {
      navigateTo({
        path: ROUTES.PROPERTY_DETAIL.replace(':propertyId', propertyId),
      }, {
        redirectCode: 301,
      })
    } else {
      navigateTo({
        path: '/not-found',
      }, {
        redirectCode: 302,
      })
    }
  }
})
</script>